
export const CommonConstant = {
    token: "token",
    orgCode: "orgCode",
    user: "user",
    studentId: "studentId",
    employeeId: "employeeId",
    defaultPageSize: 10,
    reportDownloadPageSize: 1000,
};


export const Images = {
    user: "assets/images/default_user.png",
    school: "assets/images/default_group.jpeg",
    book: "assets/images/add-book.png",
};

export const Type = {
    school: "School",
    collage: "Collage",
};

export interface Comment {
    taskId:number,
    comment:string,
  }
