import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CRUDService, BaseService, CommonUtility, CommonConstant } from "projects/common/src/public-api";
import { APIConstant, PublicAPI } from "../constant";
import { Classroom, Course, Degree, Department, School } from "models";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Images } from "@attendance-core";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})

export class ProgressPathService extends CRUDService<any> {
    constructor(protected baseService: BaseService,
        private http: HttpClient) {
        super(baseService, APIConstant.progresspath + "/");
    }





    getTaskDefByDeptName(departmentName) {
        return this.baseService.get<any>(`${APIConstant.tasksByDeptName}` + "?responsibleTeam=" + departmentName)
    }
    getAllEmployeeImages(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.basePath}/documents/allFilesInFolder?module=Employees`);
    }

    getAllFilesInFolder(taskId): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.basePath}/documents/allFilesInFolder?module=ProgressPath&folderName=` + taskId);
    }

    getDepartments() {
        return this.baseService.get(`${APIConstant.departments}`);
    }

    getStatusByDepatment(department) {
        return this.baseService.get(`${APIConstant.statusByDepartment}?departmentName=${department}`);
    }

    deleteStatus(statusId, department) {
        return this.baseService.delete(`${APIConstant.deleteStatusByDepartment}?departmentName=${department}&statusId=${statusId}`);
    }

    getDefaultTaskStates() {
        return this.baseService.get(`${APIConstant.defaultTaskStates}`);
    }

    addStatus(data) {
        return this.baseService.post(`${APIConstant.addStatus}?departmentName=${data.departmentName}`, data);
    }

    updateStatus(data) {
        return this.baseService.put(`${APIConstant.updateStatus}?departmentName=${data.departmentName}&statusId=${data.statusId}`, data);
    }

    getMyTaskStatus(employeeProfileId) {
        return this.baseService.get(`${APIConstant.getAllTasksStatisticsByEmployeeProfileId}?assignedEmpId=${employeeProfileId}`);
    }

    getTeamTaskStatus(departmentName) {
        return this.baseService.get(`${APIConstant.getAllTasksStatisticsByDeptName}?deptName=${departmentName}`);
    }

    getEmployeeInfo(employeeProfileId: any): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.employeeInfo}/basicInfo?employeeProfileId=` + employeeProfileId);
    }

    getAllTasksByEmployeeProfileId(employeeProfileId) {
        return this.baseService.get(`${APIConstant.getAllTasks}?assignedEmpId=${employeeProfileId}`);
    }

    getAllTasksByEmployeeProfileIdForDashboard(employeeProfileId) {
        return this.baseService.get(`${APIConstant.getAllTasks}?assignedEmpId=${employeeProfileId}` + '&isDashboard=true');
    }

    getAllTasksByDepartmentName(departmentName) {
        return this.baseService.get(`${APIConstant.getAllTasksByDepartmentName}?responsibleTeam=${departmentName}` + '&isDashboard=true');
    }
    getAgingReport(employeeProfileId) {
        return this.baseService.get(`${APIConstant.getAgingReport}?assignedEmpId=${employeeProfileId}`);
    }

    getAgingReportByDepartment(department) {
        return this.baseService.get(`${APIConstant.getAgingReportByDept}?departmentName=${department}`);
    }

    searchTaskWithInputText(searchString, departmentName) {

        return this.baseService.get(`${APIConstant.searchWithString}?searchString=` + searchString);
    }

    searchTasksWithInputObject(data, departmentName) {
        return this.baseService.post(`${APIConstant.advanceSearch}`, data);
    }

    addTask(input) {
        return this.baseService.post(`${APIConstant.addTask}`, input);
    }

    updateTask(input) {
        return this.baseService.put(`${APIConstant.addTask}`, input);
    }

    getAllSprintsData(department) {
        return this.baseService.get(`${APIConstant.allSprints}?departmentName=${department}`);
    }

    deleteSprint(sprintId) {
        return this.baseService.delete(`${APIConstant.sprint}?sprintId=${sprintId}`);
    }

    addSprint(data) {
        return this.baseService.post(`${APIConstant.sprint}`, data);
    }

    updateSprint(data) {
        return this.baseService.put(`${APIConstant.sprint}?sprintId=${data.sprintId}`, data);
    }

    getAllBacklogSprintTasks(department) {
        return this.baseService.get(`${APIConstant.backlogSprintTasks}?teamName=${department}`);
    }

    getAllSprintTasks(department) {
        return this.baseService.get(`${APIConstant.allSprintTasks}?teamName=${department}`);
    }

    moveFromBacklogToActive(taskId: any, sprintId: any) {
        return this.baseService.put(`${APIConstant.moveFromBacklogToActive}?taskId=${taskId}&sprintId=${sprintId}`, null);
    }

    moveFromActiveToBacklog(taskId: any) {
        return this.baseService.put(`${APIConstant.moveFromActiveToBacklog}?taskId=${taskId}`, null);
    }

    startSprint(data) {
        return this.baseService.put(`${APIConstant.startSprint}`, data);
    }

    getActiveSprint(department) {
        return this.baseService.get(`${APIConstant.activeSprint}?teamName=${department}`);
    }

    getIncompletedTasks(department, sprintId) {
        return this.baseService.get(`${APIConstant.incompletedTasks}?teamName=${department}&sprintId=${sprintId}`);
    }

    getAllEmployees() {
        return this.baseService.get(`${APIConstant.all}`)
    }

    getAllSprintsbydept(data: any) {
        return this.baseService.get(`${APIConstant.sprint}/all/completed?departmentName=${data}`)
    }

    getAllEmpData(department: any, sprintId: any, employeeProfileId: any) {
        return this.baseService.get(`${APIConstant.allEmp}?departmentName=${department}&sprintId=${sprintId}&assignedEmpId=${employeeProfileId}`)
    }

    getAllDepts() {
        return this.baseService.get(`${APIConstant.departments}`)
    }

    getALlTaskBySprint(department, data: number) {
        return this.baseService.get(`${APIConstant.taskBySprint}?departmentName=${department}&sprintId=${data}`)
    }

    statisticdata(data: any) {
        return this.baseService.post(`${APIConstant.taskReport}`, data)
    }
    allDueandOverDue() {
        return this.baseService.get(`${APIConstant.taskDueAndOverDue}`)
    }

    postOverDue(data: any, index: number) {
        return this.http.post(`${APIConstant.overDueData}?overDueDays=` + index, data);

    }

    changeStatusOrder(departmentName, fromOrder, toOrder) {
        return this.http.put(`${APIConstant.changeStatusOrder}?fromOrder=${fromOrder}&toOrder=${toOrder}&departmentName=${departmentName}`, null);
    }

    searchData(data: any) {
        return this.baseService.get(`${APIConstant.searchData}?searchBy=${data}`)
    }

    searchPopup(data: any) {
        return this.baseService.post(`${APIConstant.searchNewData}`, data)
    }

    addHolidays(data: any) {
        return this.baseService.post(`${APIConstant.addHolidays}`, data);
    }

    getAllocationReport(sprintId, department) {
        return this.baseService.get(`${APIConstant.allocationReport}?sprintId=` + sprintId + "&departmentName=" + department);
    }

    getTaskByTaskId(taskId) {
        return this.baseService.get(`${APIConstant.getTask}?taskId=${taskId}`);
    }

    advanceSprintPlanningBacklogSearchFilter(params: any, data: any): Observable<any> {
        return this.baseService.post(`${APIConstant.advanceSearchSprintPlanningBacklog}?teamName=${params}`, data);
    }

    advanceSprintPlanningCurrentSearchFilter(params: any, data: any): Observable<any> {
        return this.baseService.post(`${APIConstant.advanceSearchSprintPlanningCurrent}?teamName=${params}`, data);
    }

    searchSprintPlanningBacklog(seach: string, department: any): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            searchString: seach,
            teamName: department
        });
        return this.baseService.get<any>(`${APIConstant.searchSprintPlanningBacklogString}?${params}`);
    }

    searchSprintPlanningCurrent(seach: string, department: any): Observable<any> {
        const params = CommonUtility.convertObjectToParams({
            searchString: seach,
            teamName: department
        });
        return this.baseService.get<any>(`${APIConstant.searchSprintPlanningCurrentString}?${params}`);
    }

    moveTaskToBacklog(data) {
        return this.baseService.put(`${APIConstant.moveTaskToBacklog}?teamName=${data.department}&sprintId=${data.sprintId}`, null);
    }

    moveTaskToSprint(data) {
        return this.baseService.put(`${APIConstant.moveTaskToSprint}?teamName=${data.department}&currentSprintId=${data.currentSprintId}&toSprintId=${data.toSprintId}`, null);
    }

    getTicketDefinationById(ticketId) {
        return this.baseService.get(`${APIConstant.ticketdefinition}?ticketNumber=${ticketId}`);
    }

    updateTicket(ticket) {
        return this.baseService.put(`${APIConstant.ticketdefinition}`, ticket);
    }
}

