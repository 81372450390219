import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,
    progresspath: `${apiPath}/progresspath`,
    departments: `${apiPath}/hr/employees/departments`,
    progressPath:`${apiPath}/progresspath/taskdefinition/getAll/EmployeeProfileId`,
    searchCards:`${apiPath}/progresspath/taskdefinition/search`,
    searchNewData:`${apiPath}/progresspath/taskdefinition/search/completed-tasks`,
    statusByDepartment: `${apiPath}/progresspath/statusdefinition/all`,
    deleteStatusByDepartment: `${apiPath}/progresspath/statusdefinition`,
    defaultTaskStates: `${apiPath}/progresspath/statedefinition/all`,
    addStatus: `${apiPath}/progresspath/statusdefinition`,
    updateStatus: `${apiPath}/progresspath/statusdefinition`,
    getAllTasksStatisticsByEmployeeProfileId: `${apiPath}/progresspath/taskdefinition/task-statistics/by-empid`,
    getAllTasksStatisticsByDeptName: `${apiPath}/progresspath/taskdefinition/task-statistics/by-deptname`,
    employeeInfo: `${apiPath}/hr/employees`,
    getAllTasks: `${apiPath}/progresspath/taskdefinition/getAll/EmployeeProfileId`,
    getAllTasksByDepartmentName:`${apiPath}/progresspath/taskdefinition/getAll/DepartmentName`,
    getAgingReport: `${apiPath}/progresspath/taskdefinition/task-agingreport/by-empid`,
    getAgingReportByDept: `${apiPath}/progresspath/taskdefinition/task-agingreport/bydepartmentName`,
    searchProBoard: `${apiPath}/progresspath/taskdefinition/search`,
    tasksByDeptName: `${apiPath}/progresspath/taskdefinition/getAllTasks/responsibleTeam`,
    searchWithString: `${apiPath}/progresspath/taskdefinition/search`,
    advanceSearch: `${apiPath}/progresspath/taskdefinition/search`,
    addTask:`${apiPath}/progresspath/taskdefinition/`,
    taskDueAndOverDue:`${apiPath}/progresspath/taskdefinition/tasksDueAndOverduereport`,
    allEmp:`${apiPath}/progresspath/sprintdefinition/all-tasks-bysprintid-byempid`,
    taskBySprint:`${apiPath}/progresspath/taskdefinition/all/bySprintId`,
    allSprints: `${apiPath}/progresspath/sprintdefinition/all-sprints-bydeptname`,
    sprint: `${apiPath}/progresspath/sprintdefinition`,
    backlogSprintTasks: `${apiPath}/progresspath/taskdefinition/backlog-tasks`,
    allSprintTasks: `${apiPath}/progresspath/taskdefinition/sprint-tasks`,
    moveFromBacklogToActive: `${apiPath}/progresspath/taskdefinition/active-sprint`,
    moveFromActiveToBacklog: `${apiPath}/progresspath/taskdefinition/backlog`,
    startSprint: `${apiPath}/progresspath/sprintdefinition/start-sprint`,
    activeSprint: `${apiPath}/progresspath/sprintdefinition/activeSprint`,
    incompletedTasks: `${apiPath}/progresspath/taskdefinition/incomplete-tasks`,
    addHolidays: `${apiPath}/progresspath/sprintholiday/list`,
    getCardHistory:`${apiPath}/progresspath/history/by-taskid`,
    getComments:`${apiPath}/progresspath/comments/`,
    postComments:`${apiPath}/progresspath/comments`,
    allocationReport:`${apiPath}/progresspath/reports/allocations`,
    overDueData:`${apiPath}/progresspath/taskdefinition/getalloverduerange/by-departments`,
    taskReport: `${apiPath}/progresspath/taskdefinition/getalloverdue-reprts/by-departments`,
    all: `${apiPath}/hr/employees/all`,
    searchData:`${apiPath}/progresspath/taskdefinition/search-by/completed-tasks`,
    changeStatusOrder:`${apiPath}/progresspath/statusdefinition/display-order`,
    getTask:`${apiPath}/progresspath/taskdefinition`,
    searchSprintPlanningBacklogString: `${apiPath}/progresspath/taskdefinition/search/backlog-tasks`,
    searchSprintPlanningCurrentString: `${apiPath}/progresspath/taskdefinition/search/CurrentSprint-tasks`,
    advanceSearchSprintPlanningCurrent: `${apiPath}/progresspath/taskdefinition/searchV2/CurrentSprint-tasks`,
    advanceSearchSprintPlanningBacklog: `${apiPath}/progresspath/taskdefinition/searchV2/backlog-tasks`,
    moveTaskToBacklog: `${apiPath}/progresspath/taskdefinition/move-to-backlog`,
    moveTaskToSprint: `${apiPath}/progresspath/taskdefinition/move-to-sprint`,
    ticketdefinition: `${apiPath}/progresspath/ticketdefinition`,
};

export const PublicAPI = [];
