import { Component, OnInit } from "@angular/core";
import { AppService, RoleService, UserAuthService } from 'projects/common/src/public-api';
import { ActivatedRoute, Router } from "@angular/router";
import { Type } from "../core/constant";
import { ProgressPathService } from "../core/service/progress-path.service";

@Component({
    template: "",
})
export class ValidateComponent implements OnInit {

    private images: any[] =[];
    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: UserAuthService,
        private router: Router,
        private appService: AppService,
        private roleService: RoleService,
        private proService:ProgressPathService) { }

    ngOnInit() {
        this.getToken();
        this.getAllEmployeeImages();
        this.getColumnConfig()
    }

    private async getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        const query = urlParams.get("query");

        if(query){
            const taskId = this.activatedRoute.snapshot.params.id;
            let queryAtob = JSON.parse(atob(query));
            const token = queryAtob["token"];
            window.sessionStorage.setItem('token', token);
            window.sessionStorage.setItem('orgCode', queryAtob['code']);
            window.sessionStorage.setItem('employeeId', queryAtob['employeeId']);
            window.sessionStorage.setItem('employeeName', queryAtob['employeeName']);
            window.sessionStorage.setItem('school', queryAtob['schoolName']);
            window.sessionStorage.setItem('userName', queryAtob['userName']);

            setTimeout(() => {
                this.router.navigateByUrl(`/task/${taskId}?query=${query}`);
            }, 900);
        }

        if (token) {
            window.sessionStorage.setItem('token', token);
            window.sessionStorage.setItem('orgCode', urlParams.get('code'));
            window.sessionStorage.setItem('employeeId', urlParams.get('employeeId'));
            window.sessionStorage.setItem('employeeName', urlParams.get('employeeName'));
            window.sessionStorage.setItem('school', urlParams.get('schoolName'));
            window.sessionStorage.setItem('userName', urlParams.get('userName'));
            this.getSecurityGroups();
            setTimeout(() => {
                this.router.navigateByUrl('/', { preserveQueryParams: false });
            }, 900);
            
            // const code = urlParams.get("code");
            // await this.authService.saveToken(token);
            // this.authService.saveOrgCode(code);
            // this.schoolService.getSchoolProfileType().subscribe((response) => {
            //     this.router.navigateByUrl(response.type === Type.school ? "/class-section" : "/degree-department", {
            //         preserveQueryParams: false,
            //     });
            // });
            // this.getSecurityGroups();
            // this.getColumnConfig();
            // setTimeout(() => {
            //     this.router.navigateByUrl('/dashboard', { preserveQueryParams: false });
            // }, 0);
        }
    }

    private getSecurityGroups() {
        this.roleService.getSecurityGroups('ProgressPath');
      }

      private getAllEmployeeImages() {
        this.proService.getAllEmployeeImages().subscribe((response) => {
           this.images = response;
           console.log("images "+JSON.stringify(this.images))
              sessionStorage.setItem('employeeImages',JSON.stringify(this.images));
    
        });
      }
      private getColumnConfig() {
        this.appService.datatableSetup('PROGRESSPATH');
    }
}
