import { Component, OnInit } from '@angular/core';
import { AppService, RoleService, UserAuthService } from 'projects/common/src/public-api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private authService: UserAuthService,
    private roleService: RoleService,
    private appService: AppService
    ) {
  }

  ngOnInit() {
    this.getToken();
    // this.getSecurityGroups();
    this.getColumnConfig();
  }

  private getToken() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const orgCode = urlParams.get('code');
    if (token) {
      this.authService.saveToken(token)
    }
    if (orgCode) {
      this.authService.saveOrgCode(orgCode)
    }
    const employeName = urlParams.get('employeeName');
		if (employeName) {
			this.authService.saveEmployeeName(employeName)
		}
    const schoolName = urlParams.get('schoolName');
		if (schoolName) {
			this.authService.saveSchoolName(schoolName)
		}
    const employeeId = urlParams.get('employeeId');
		if (employeeId) {
			this.authService.saveEmployeeId(employeeId)
		}
    const userName = urlParams.get('userName');
		if (userName) {
			this.authService.saveUserName(userName)
		}

    this.getSecurityGroups();
  }

  private getSecurityGroups() {
    this.roleService.getSecurityGroups('ProgressPath');
  }
  private getColumnConfig() {
    this.appService.datatableSetup('PROGRESSPATH');
}
  // private getSecurityGroups() {
  //   this.roleService.getSecurityGroups('Academics');
  // }
}
